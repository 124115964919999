@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'mapbox-gl/dist/mapbox-gl.css';

#mapbox_total {
    height: 100vh;
}

.mapboxgl-popup-content {
    min-width: 300px;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    @apply shadow-sm;

}

.mapboxgl-popup-close-button {
    margin-right: 9px;
    margin-top: 4px;
    font-size: 16px;
}

.strawalky-popup h2 {
    font-size: 14px;
    font-weight: 500;
    @apply text-gray-700;
    padding-bottom: 5px;
}

.strawalky-popup p {
    font-size: 12px;
}

.strawalky-popup span {
    color: #777;
    display: block;
    font-size: 12px;
}
